import { FC, useEffect, useState } from "react";
import { Constants } from "../../constants";

interface MyDeviceProps {
  devices: { id: string; name: string }[];
  onRemove: (id: string) => void;
}

const DevicesList: FC<MyDeviceProps> = (props) => {
  const { devices, onRemove } = props;

  return (
    <>
      {devices.map((item) => (
        <tr key={item.id}>
          <td>{item.id}</td>
          <td>{item.name}</td>
          <td>
            <button
              onClick={() => onRemove(item.id)}
              className="flex px-4 py-2 text-sm font-medium text-white bg-gray-700 border border-transparent rounded-md shadow-sm hover:bg-gray-800"
            >
              {Constants.UNLINK_DEVICE}
            </button>
          </td>
        </tr>
      ))}
    </>
  );
};

const LoadingDevices: FC = () => {
  return (
    <tr>
      <td colSpan={3}>
        <div className="py-6 text-lg">Loading devices...</div>
      </td>
    </tr>
  );
};

const MyDevices: FC<MyDeviceProps> = (props) => {
  const { devices, onRemove } = props;
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(devices && devices.length > 0);
  }, [devices]);

  return (
    <div className="mx-auto divide-y-2 divide-gray-200 ">
      <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl mt-16">Your Devices</h2>
      <table className="border-collapse border border-gray-400 mt-4 w-full">
        <thead>
          <tr className="bg-gray-300 text-black text-xl">
            <th className="w-3/8">{Constants.DEVICE_ID}</th>
            <th className="w-3/8">{Constants.DEVICE_NAME}</th>
            <th className="w-1/4">{Constants.ACTION}</th>
          </tr>
        </thead>
        <tbody>{(loading && <DevicesList devices={devices} onRemove={onRemove} />) || <LoadingDevices />}</tbody>
      </table>
    </div>
  );
};

export default MyDevices;
