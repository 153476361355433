import { Context, createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from "react";
import { DeviceSimulator } from "../openapi/device-management-api/api";
import useDeviceAPI from "./hooks/useDeviceApi";
import { DeviceConfig } from "./models";
import useDeviceManagementAPI from "./hooks/useDeviceManagementApi";
import { useV2Devices } from "./hooks/useV2Devices";
import { useAuth, Tokens } from "./hooks/useAuth";

type GlobalState = {
  deviceConfig: DeviceConfig;
  // TODO: [SimulatorRefactor] alias this type to "device" rather than deviceSimulator
  devices: DeviceSimulator[];
  tokens: Tokens;
  // TODO: [SimulatorRefactor] remove
  setDeviceConfig: Dispatch<SetStateAction<DeviceConfig>>;
  // TODO: [SimulatorRefactor] SelectedDevice should contain all of the related data (tokens and deviceConfig.
  selectedDevice: DeviceSimulator;
  // TODO: [SimulatorRefactor] remove .. also alias type to device.
  setSelectedDevice: Dispatch<SetStateAction<DeviceSimulator>>;
  // TODO: [SimulatorRefactor] remove sandbox; prefer "embedded application config"
  sandboxParams: string;
  setSandboxParams: Dispatch<SetStateAction<string>>;
  // FIXME: [SimulatorRefactor] Move this outside of here.
  getDevices: () => void;
  isLoading: boolean;
};

// FIXME: [SimulatorRefactor] Split context: data from dispatch
const GlobalStateContext: Context<GlobalState> = createContext<GlobalState>({
  deviceConfig: {
    deviceId: null,
    terminalId: null,
  },
  devices: null,
  tokens: {},
  setDeviceConfig: () => {},
  selectedDevice: undefined,
  setSelectedDevice: () => {},
  setSandboxParams: () => {},
  sandboxParams: null,
  getDevices: () => {},
  isLoading: true,
});

// FIXME: [SimulatorRefactor] redundant? (possibly for tests?)
export const useGlobalState = () => {
  const ctx = useContext(GlobalStateContext);

  if (!ctx) {
    throw new Error("useGlobalState must be used within a GlobalStateProvider component");
  }

  return ctx;
};

export function GlobalStateProvider({ children }: { children: ReactNode }): JSX.Element {
  const [deviceConfig, setDeviceConfig] = useState<DeviceConfig>({
    deviceId: null,
    terminalId: null,
  });
  const [devices, setDevices] = useState<DeviceSimulator[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sandboxParams, setSandboxParams] = useState<string>(null);
  const { tokens, getTokens } = useAuth();
  const v2_devices = useV2Devices();
  const [selectedDevice, setSelectedDevice] = useState<DeviceSimulator>(undefined);
  // FIXME: [SimulatorRefactor] DeviceAPI rename -> SimulatorAPI
  const deviceApi = useDeviceAPI();
  const deviceManagementApi = useDeviceManagementAPI();

  async function getDevices() {
    if (!deviceApi) {
      return;
    }

    try {
      const apiDevicesIds = await deviceApi.deviceGet();
      setIsLoading(false);

      if (v2_devices) {
        if (apiDevicesIds.status === 200 && apiDevicesIds.data.devices) {
          const ids = apiDevicesIds.data.devices.map((device) => device.id);

          const apiDevices = await deviceManagementApi.v2SimulatorGetDevices(ids);
          if (apiDevices.status === 200 && apiDevices.data.items) {
            // TODO: [SimulatorRefactor] return instead of setting directly (applies to all setDevices() calls in method)
            setDevices(apiDevices.data.items);
          }
        } else {
          setDevices([]);
        }
      } else {
        if (apiDevicesIds.status === 200 && apiDevicesIds.data.devices) {
          setDevices(apiDevicesIds.data.devices);
        } else {
          setDevices([]);
        }
      }
    } catch (e) {
      const err = e as Error;
      console.error(`Failed to fetch devices: ${err}`);
    }
  }

  // FIXME: [SimulatorCleanup] Fetch when ready on devices onLoad and whenever devices change only.
  useEffect(() => {
    async function run() {
      await getDevices();
    }

    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceManagementApi]);

  useEffect(() => {
    async function run() {
      await getTokens(deviceApi, selectedDevice);
    }

    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDevice]);

  // TODO: [SimulatorCleanup] invalidate previous device immediately
  useEffect(() => {
    if (!selectedDevice && devices && devices.length > 0) {
      setSelectedDevice(devices[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices, tokens]);

  return (
    <GlobalStateContext.Provider
      value={{
        deviceConfig,
        setDeviceConfig,
        devices,
        tokens,
        selectedDevice,
        setSelectedDevice,
        setSandboxParams,
        sandboxParams,
        getDevices,
        isLoading,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
}
